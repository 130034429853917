import { ReactNode, useEffect, useState } from 'react'
import { Theme } from 'shared/contexts/LayoutContext'
import { getOrgThemeConfigurations } from 'shared/userServiceClient'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import {
  darkTheme,
  generalTheme,
  lightTheme,
  OrgThemeConfig,
} from './appThemes'

export const GlobalStyles = createGlobalStyle`
* {
    outline: none;
    outline-offset: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Open Sans', sans-serif;
}

*:focus {
  outline: none !important;
}

body {
  background: ${(props) => props.theme.palette.grey[0]};
}
`

interface Props {
  children: ReactNode
  theme: Theme
}

function getSelectedThemeMode(theme: Theme) {
  if (theme === 'machine') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }
  return theme
}

const ThemeContextProvider = ({ children, theme }: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const selectedThemeMode = getSelectedThemeMode(theme)
  const [selectedOrgThemeConfig, setSelectedOrgThemeConfig] =
    useState<OrgThemeConfig>()

  useEffect(() => {
    ;(async () => {
      try {
        const themeConfigs = await getOrgThemeConfigurations()

        const selectedTheme = themeConfigs?.find(
          (v) => v.themeType === selectedThemeMode,
        )

        if (selectedTheme === undefined) {
          setSelectedOrgThemeConfig(
            selectedThemeMode === 'light' ? lightTheme : darkTheme,
          )
        } else {
          setSelectedOrgThemeConfig(selectedTheme)
        }
      } catch (error) {
        console.error('Error fetching or setting theme colors:', error)
        setSelectedOrgThemeConfig(
          selectedThemeMode === 'light' ? lightTheme : darkTheme,
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [theme])

  if (loading && !selectedOrgThemeConfig) {
    return <></>
  }

  return (
    <ThemeProvider theme={generalTheme(selectedOrgThemeConfig!)}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default ThemeContextProvider
