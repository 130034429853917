import {
  type ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import type { WizardStepProps } from 'shared/types'
import styled from 'styled-components'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import { convertToCVS, downloadContent } from 'shared/helpers'
import { getPodList } from 'shared/podServiceClient'
import type { Pod } from '../../insightsManager/types'
import {
  convertExpandedTradesPodData,
  convertIbtCsvToJson,
  convertLsTimeToISODateFormat,
} from '../helpers'
import type { ExpandedTradesPodData, IbtCsvRowItem } from '../types'
import LSPodList from './LSPodList'
import LayoutContext from 'shared/contexts/LayoutContext'
import { getExpandedTradesPodData } from 'shared/loadSchedulingClient'
import { getInternalBilateralTableSchema } from '../schemas'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import {
  faCheck,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { IWFileInput } from 'shared/components/thunderbolt/IWTextInput'
import IWContextDivider from 'shared/components/thunderbolt/IWContextMenuItemDivider'
import { TIMEZONES } from 'shared/constants'

const StyledActionableSection = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-end;
`

const StyledBadge = styled(IWGeneralBadge)`
  text-transform: none;
`

type StepState = {
  internalBilaterals: IbtCsvRowItem[]
  selectedFilename?: string
}

export type Props = WizardStepProps<StepState>

const toastOptions = {
  canDismiss: true,
  duration: 10_000,
}

const FileNameBadge = ({
  filename,
  onIconClick,
}: {
  filename: string | undefined
  onIconClick: () => void
}) => {
  const { t } = useTranslation()
  const title = t('loadScheduling.uploadedFilenameLabel')

  return (
    <IWBadgeGroup>
      {filename ? (
        <StyledBadge
          data-testid="ls-filename-badge"
          label={`${title}: ${filename || 'No file selected'}`}
          color="primary"
          icon={faTimes}
          iconPosition="trailing"
          onIconClick={onIconClick}
        />
      ) : (
        <StyledBadge
          data-testid="ls-filename-badge"
          label={`${title}: ${filename || 'No file selected'}`}
          color="primary"
        />
      )}
    </IWBadgeGroup>
  )
}

const LSSelectIBTLoadStep = ({ state, onChange }: Props) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedPodIds, setSelectedPodIds] = useState<string[]>([])
  const [hasErrors, setHasErrors] = useState(false)
  const [currentState, setCurrentState] = useState<IbtCsvRowItem[]>(
    state?.internalBilaterals || [],
  )

  const filesRef = useRef<HTMLInputElement | null>(null)
  const [selectedFilename, setSelectedFilename] = useState<string | undefined>(
    state?.selectedFilename || undefined,
  )

  const { data: pods, isLoading: isLoadingPods } = useQuery(
    ['shortTermForecast-ls-ibt-submit'],
    () => getPodList(0, 100, '', ['riskManagement']),
  )

  const clearInputFiles = (): void => {
    if (filesRef?.current) {
      filesRef.current.value = ''
    }

    setSelectedFilename(undefined)
    setCurrentState([])
  }

  const handleOnUpload = async (files: File[]) => {
    try {
      if (files.length === 0) {
        return
      }

      const [file] = files
      const { name: fileName } = file

      const ibts = await convertIbtCsvToJson([file])

      setSelectedPodIds([])
      setCurrentState(ibts)
      setHasErrors(false)

      setSelectedFilename(fileName)

      toast.grey(t('loadScheduling.toasts.uploadSuccessful'), {
        icon: faCheck,
        description: fileName,
        canDismiss: true,
        duration: 5_000,
        actionButtons: [
          {
            text: '',
            action: () => {},
          },
        ],
      })
    } catch (e) {
      setHasErrors(true)
      setCurrentState([])
      setSelectedPodIds([])
      setSelectedFilename(undefined)

      toast.alert(t('errors.generic.processing'), toastOptions)
      console.error(e)
    }
  }

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const files: File[] = event && Array.from(event.target.files || [])
    const hasFiles = Boolean(files?.length > 0)

    if (!hasFiles) {
      clearInputFiles()
    }

    handleOnUpload(files)
  }

  const handleOnDownload = async ({
    datasource = '',
    userPodId,
    userPodName,
  }: Pod) => {
    try {
      setIsDownloading(true)

      const { events } = await getExpandedTradesPodData(datasource, timezone)
      const formatted = events.map((v: ExpandedTradesPodData) => {
        v.__time = convertLsTimeToISODateFormat(v.__time)
        return v
      })

      const { items: ibts, hasMissingMappings } =
        await convertExpandedTradesPodData(formatted)

      const csv = convertToCVS(
        ibts,
        getInternalBilateralTableSchema().map(({ accessor }) => accessor),
      )

      // if (hasMissingMappings) {
      //   toast.warning(t('loadScheduling.toasts.missingMappingsFoundTitle'), {
      //     icon: faExclamationCircle,
      //     description: t('loadScheduling.toasts.missingMappingsFoundDesc'),
      //     canDismiss: true,
      //     duration: 15_000,
      //     actionButtons: [
      //       {
      //         text: '',
      //         action: () => {},
      //       },
      //     ],
      //   })
      // }

      downloadContent({
        filename: `${userPodName}-${userPodId}`,
        content: csv,
        ext: 'csv',
        mediaType: 'text/csv',
      })
    } catch (e) {
      console.error(e)
      toast.alert(t('errors.generic.processing'), toastOptions)

      setHasErrors(true)
      setCurrentState([])
    } finally {
      setIsDownloading(false)
    }
  }

  const handleOnSelect = async ({
    datasource = '',
    userPodId,
    markets,
  }: Pod) => {
    try {
      const [currentMarket] = markets

      if (userPodId === selectedPodIds[0]) {
        return
      }

      setCurrentState([])
      setIsDownloading(true)
      setSelectedPodIds([userPodId])

      const marketTimezone = TIMEZONES[currentMarket.toUpperCase()]

      const { events } = await getExpandedTradesPodData(
        datasource,
        marketTimezone,
      )

      const formatted = events.map((v: ExpandedTradesPodData) => {
        v.__time = convertLsTimeToISODateFormat(v.__time)
        return v
      })

      const { items: ibts, hasMissingMappings } =
        await convertExpandedTradesPodData(formatted)

      setCurrentState(ibts)
      setHasErrors(false)

      // if (hasMissingMappings) {
      //   toast.warning(t('loadScheduling.toasts.missingMappingsFoundTitle'), {
      //     icon: faExclamationCircle,
      //     description: t('loadScheduling.toasts.missingMappingsFoundDesc'),
      //     canDismiss: true,
      //     duration: 15_000,
      //     actionButtons: [
      //       {
      //         text: '',
      //         action: () => {},
      //       },
      //     ],
      //   })
      // }
    } catch (e) {
      console.error(e)
      toast.alert(t('errors.generic.processing'), toastOptions)

      setHasErrors(true)
      setCurrentState([])
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    onChange(
      { internalBilaterals: currentState },
      !hasErrors && currentState.length > 0,
    )
  }, [hasErrors, currentState])

  return (
    <>
      <div style={{ marginBottom: '0rem' }}>
        <IWFormSection
          sectionTitle={t('loadScheduling.sections.uploadTitle')}
          sectionDescription={t('loadScheduling.sections.uploadDescription')}
        >
          <FileNameBadge
            filename={selectedFilename}
            onIconClick={clearInputFiles}
          />

          <StyledActionableSection>
            <div style={{ display: 'none' }}>
              <IWFileInput
                ref={filesRef}
                type="file"
                name="file"
                accept=".csv"
                onChange={onFileSelect}
              />
            </div>

            <IWButton
              variant="outline"
              onClick={() => filesRef.current?.click()}
            >
              {t('button.upload')}
            </IWButton>
          </StyledActionableSection>
        </IWFormSection>
      </div>

      <IWContextDivider />

      <IWFormSection
        sectionTitle={t('loadScheduling.sections.selectPodTitle')}
        sectionDescription={t('loadScheduling.sections.selectPodDescription')}
      >
        {isLoadingPods && <IWLoading />}
        {!isLoadingPods && pods?.total === 0 && <IWNoResultsMessage />}

        {pods && pods.total > 0 && (
          <LSPodList
            pods={pods.rows.map((p) => ({
              ...p,
              currentStatus:
                isDownloading && p.userPodId === selectedPodIds[0]
                  ? 'running'
                  : 'complete',
              currentStatusMessage: t('placeholders.loading'),
            }))}
            isLoading={isDownloading}
            onDownload={handleOnDownload}
            onSelect={handleOnSelect}
            selectedPodIds={selectedPodIds}
          />
        )}
      </IWFormSection>
    </>
  )
}

export default LSSelectIBTLoadStep
