import {
  faProjectDiagram,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { OrgThemeConfig } from 'appThemes'
import i18next from 'i18next'
import {
  LoadSchedulingSubmissionBody,
  Notification,
  PodSharingBody,
  PodStatusBody,
} from '../tools/insightsManager/types'
import axiosApiClient from './apiClient'

export type OrgUser = {
  id: string
  email: string
  name: string
}

export type OrgList = Array<string>

export const getOrgUsers = async () => {
  const orgUsers = await axiosApiClient
    .get('/user/v1/orgs/users')
    .then((r) => r.data as OrgUser[])
  const obj: { [orgUserId: string]: OrgUser } = {}
  orgUsers.forEach((user) => {
    obj[user.id] = user
  })
  return {
    list: orgUsers.sort((a, b) => a.name.localeCompare(b.name)),
    obj,
  }
}

export async function getNotifications({
  pageParam = 0,
  limit = 30,
}: {
  pageParam?: number
  limit?: number
}): Promise<Notification[]> {
  const { data } = await axiosApiClient.get('/user/v1/user/notifications', {
    params: {
      limit,
      skip: limit * pageParam,
    },
  })

  const orgUsers = await getOrgUsers()

  return data
    .map((n) => {
      if (n.notificationType === 'podStatus') {
        const { userPodId, userPodName, wasSuccessful } =
          n.body as PodStatusBody
        const title = wasSuccessful
          ? i18next.t('notifications.title.podBuildSuccess', {
              name: userPodName,
            })
          : i18next.t('notifications.title.podBuildFail', {
              name: userPodName,
            })
        return {
          ...n,
          link: `/tools/insights-manager/pod-details/${userPodId}`,
          title,
          icon: !wasSuccessful ? faTriangleExclamation : undefined,
        }
      }
      if (n.notificationType === 'podSharing') {
        const { userPodId, userPodName, sharingStatus, userIdThatShared } =
          n.body as PodSharingBody
        const title = sharingStatus
          ? i18next.t('notifications.title.podWasShared', {
              name: userPodName,
            })
          : i18next.t('notifications.title.podWasUnShared', {
              name: userPodName,
            })
        return {
          ...n,
          link: `/tools/insights-manager/pod-details/${userPodId}`,
          title,
          subtitle: i18next.t('notifications.title.userThatShared', {
            name:
              orgUsers.obj[userIdThatShared]?.name ||
              i18next.t('insightManager.pods.sharing.unknownUser'),
          }),
          icon: sharingStatus ? faProjectDiagram : faTriangleExclamation,
        }
      }
      if (n.notificationType === 'podAlert') {
        const { userAlertId, userAlertName, numMatches } = n.body
        const title = i18next.t('notifications.title.podAlert', {
          name: userAlertName,
        })
        return {
          ...n,
          title,
          link: `/tools/alerts-manager/alert-details/${userAlertId}`,
          subtitle: i18next.t('notifications.title.podAlertMatchesCount', {
            count: numMatches,
          }),
        }
      }
      if (n.notificationType === 'loadSchedulingSubmission') {
        const { submissionId, market, submissionRunStatus, wasSuccessful } =
          n.body as LoadSchedulingSubmissionBody
        let title
        switch (submissionRunStatus) {
          case 'processingSubmission': {
            title = i18next.t('notifications.title.lsSubmitInProgress', {
              market: market,
            })
            break
          }
          case 'completedSubmission': {
            title = i18next.t(
              'notifications.title.lsSubmitCompletedSuccessfully',
              {
                market: market,
              },
            )
            break
          }
          case 'failedSubmisssion': {
            title = i18next.t('notifications.title.lsSubmitFailed', {
              market: market,
            })
            break
          }
          default:
            title = ''
        }
        return {
          ...n,
          link: `/tools/insights-manager/pod-details`,
          title,
          subtitle: i18next.t('notifications.refId', {
            id: submissionId,
          }),
          icon: !wasSuccessful ? faTriangleExclamation : undefined,
        }
      }
      return null
    })
    .filter((n) => n !== null)
}

export function getUnreadNotifications(): Promise<number> {
  return axiosApiClient
    .get('/user/v1/user/notifications/count/?isRead=false')
    .then((r) => r.data.count as number)
}

export function toggleNotificationIsRead(notificationId: string) {
  return axiosApiClient.patch(
    `/user/v1/user/notifications/toggle/${notificationId}`,
  )
}

export function markAllAsRead() {
  return axiosApiClient.patch(`/user/v1/user/notifications/mark-all-as-read`)
}

export const getAllOrgs = async () => {
  const orgUsers = await axiosApiClient
    .get('/user/v1/orgs/all-orgs')
    .then((r) => r.data as OrgList)
  return orgUsers
}

export async function getOrgThemeConfigurations(): Promise<OrgThemeConfig[]> {
  const themeConfiguration = await axiosApiClient
    .get('/user/v1/theme')
    .then((r) => r.data as OrgThemeConfig[])
  return themeConfiguration
}

export async function getUserThemeColorMode(): Promise<'light' | 'dark'> {
  const {
    data: { colorMode },
  } = await axiosApiClient.get('/user/v1/theme/user-theme-color-mode')
  return colorMode
}

export async function updateUserThemeColorMode(
  colorMode: 'light' | 'dark' | 'machine',
): Promise<void> {
  await axiosApiClient.post('/user/v1/theme/user-theme-color-mode', {
    colorMode,
  })
}
