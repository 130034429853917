import { groupBy } from 'lodash'
import { DateTime } from 'luxon'
import { divide, isNaN, number as toNumber, round } from 'mathjs'
import { Column } from 'shared/components/thunderbolt/IWTable'
import { TIMEZONES } from 'shared/constants'
import { csvToJson, validateArrayWithZod } from 'shared/helpers'
import {
  getDefaultPriceInfo,
  getInternalBilateralContracts,
  getLocationAttributes,
  getPodData,
  getPricingInfo,
  getRoundingInfo,
  getTargetNetOpenPositions,
  LocationAttribute,
} from 'shared/loadSchedulingClient'
import { Pod } from 'tools/insightsManager/types'
import {
  AggregatedExpandedTrades,
  DayAheadDemandBid,
  ExpandedTradesPodData,
  IbtCsvRowItem,
  InternalBilateral,
  LsCsvRowItem,
  LsErcotCsvRowItem,
  NOPPodItem,
  STFPodItem,
} from './types'
import {
  ExpandedTradesPodCsvRowValidation,
  IbtCsvRowValidation,
  LsCsvRowValidation,
  LsErcotCsvRowValidation,
  STFPodCsvRowValidation,
} from './validations'

type LoadSchedulingAggregation = {
  market: string
  brand: string
  zone: string
  startTime: string
  isoDate: string
  date: string
  interval: number
  khz: number
  mhz: number
  location: LocationAttribute['location']
  locationName: LocationAttribute['locationName']
}

export const NOT_FOUND_LOCATION = 'MAPPING_NOT_FOUND'

export function applyIsoLoadRules(market: string, load: number) {
  let currentLoad = isNaN(load) ? 0 : load

  if (currentLoad < 0) {
    currentLoad = 0
  }

  return market === 'NYISO' ? round(currentLoad, 0) : round(currentLoad, 1)
}

export function applyIsoLoadRulesErcot(
  load: number,
  rules: { bid: number; offer: number },
  bidOrOffer: 'EnergyBid' | 'EnergyOnlyOffer',
) {
  let currentLoad = isNaN(load) ? 0 : load

  if (currentLoad < 0) {
    currentLoad = 0
  }

  if (
    bidOrOffer === 'EnergyBid' &&
    currentLoad >= rules.bid &&
    currentLoad < 1
  ) {
    currentLoad = round(load, 0)
  } else if (
    bidOrOffer === 'EnergyOnlyOffer' &&
    currentLoad >= rules.offer &&
    currentLoad < 1
  ) {
    currentLoad = round(load, 0)
  } else if (currentLoad < 1) {
    currentLoad = 0
  } else {
    currentLoad = round(load, 1)
  }

  return currentLoad
}

export function formatToConvertPayload(
  items: LsCsvRowItem[] = [],
): DayAheadDemandBid[] {
  return items.map((item) => {
    return {
      market: item.market,
      brand: item.brand,
      date: item.date,
      zone: item.zone,
      location: item.location,
      intervals: Array.from({ length: 24 }, (_, i) => ({
        interval: i + 1,
        load: item[`${i + 1}`],
      })),
    }
  })
}

export function formatToIbtConvertPayload(
  items: IbtCsvRowItem[] = [],
): InternalBilateral[] {
  return items.map((item) => {
    return {
      contract_id: item.contract_id,
      contract_name: item.contract_name,
      market: item.market,
      brand: item.brand,
      zone: item.zone,
      buyer: item.buyer,
      buyer_qse_code: item.buyer_qse_code,
      seller: item.seller,
      seller_qse_code: item.seller_qse_code,
      date: item.date,
      intervals: Array.from({ length: 24 }, (_, i) => ({
        interval: i + 1,
        load: item[`${i + 1}`],
      })),
    }
  })
}

export function aggregateHours(
  podItems: Record<
    string,
    ({ location: string; location_name: string } & STFPodItem)[]
  >,
): LoadSchedulingAggregation[] {
  const aggregations: LoadSchedulingAggregation[] = []

  for (const marketKey in podItems) {
    const items = podItems[marketKey]
    if (!items || items.length === 0) {
      continue
    }

    const {
      start_time: startTime,
      market,
      zone,
      location,
      brand,
      location_name: locationName,
    } = items[0]

    const dateTime = DateTime.fromISO(startTime.toString(), {
      zone: TIMEZONES[market],
    })

    const khz = items.reduce((previousValue, currentValue) => {
      const forecastGross = Number(currentValue.forecast_gross)
      return previousValue + (isNaN(forecastGross) ? 0 : forecastGross)
    }, 0)

    const load = divide(khz, 1000)
    const mhz = applyIsoLoadRules(market, toNumber(load))

    aggregations.push({
      market,
      brand,
      zone,
      location,
      locationName,
      startTime,
      isoDate: dateTime.toISO(),
      date: dateTime.toISODate(),
      interval: dateTime.hour + 1,
      khz,
      mhz,
    })
  }

  return aggregations
}

export async function convertPodJsonToLsJson(stf: STFPodItem[]): Promise<{
  items: LsCsvRowItem[]
  hasMissingMappings: boolean
}> {
  if (stf.length <= 0) {
    return {
      items: [],
      hasMissingMappings: false,
    }
  }

  const validated = validateArrayWithZod<STFPodItem>(
    stf,
    STFPodCsvRowValidation,
  )

  const locationAttributes = await getLocationAttributes([], true)

  const activeLocationAttributes = locationAttributes.filter(
    (v) => v.isCredentialActive,
  )

  const attributeMap = new Map(
    activeLocationAttributes.map((attribute) => [
      `${attribute.market}-${attribute.brand}-${attribute.zone}-${attribute.utility}`,
      attribute,
    ]),
  )

  const locationAttributeKeys = Array.from(attributeMap.keys())
  const podKeys = Object.keys(
    groupBy(validated, (v) => `${v.brand}-${v.market}-${v.zone}`),
  )

  const missingMappings = podKeys.filter(
    (item) => !locationAttributeKeys.includes(item),
  )

  const hasMissingMappings = !!missingMappings?.length

  if (hasMissingMappings) {
    console.info('MISSING_MAPPINGS::', missingMappings)
  }

  const withLocations = validated
    .map((item) => {
      const { brand, market, zone, utility } = item
      const key = `${market}-${brand}-${zone}-${utility}`
      const mappedAttribute = attributeMap.get(key)

      if (mappedAttribute) {
        return {
          ...item,
          location: mappedAttribute.location,
          location_name: mappedAttribute.locationName,
        }
      }
      return undefined
    })
    .filter((item) => item)

  const groupedByMarket = groupBy(withLocations, 'market')

  const aggregatedData = Object.values(groupedByMarket).reduce(
    (memo, marketItems) => {
      const groupedByTime = groupBy(marketItems, '__time')

      const [latestTime] = Object.keys(groupedByTime).sort((a, b) =>
        b.localeCompare(a),
      )

      const latestLoadForecast = groupedByTime[latestTime]
      const group = groupBy(
        latestLoadForecast,
        (v) => `${v.market}-${v.brand}-${v.zone}-${v.location}-${v.start_time}`,
      )

      const aggregation = aggregateHours(group)
      memo.push(...aggregation)
      return memo
    },
    [] as LoadSchedulingAggregation[],
  )

  const groupByDate = groupBy(
    aggregatedData,
    (v) => `${v.market}-${v.brand}-${v.zone}-${v.location}-${v.date}`,
  )

  const lsJson = Object.values(groupByDate).reduce(
    (previousValue, currentValue) => {
      const [{ market, brand, date, zone, location, locationName }] =
        currentValue

      const bid = {
        market,
        brand,
        date,
        zone,
        location,
        locationName,
      }
      currentValue.forEach((v) => {
        bid[v.interval] = v.mhz
      })

      previousValue.push(bid)
      return previousValue
    },
    [] as LsCsvRowItem[],
  )

  return {
    items: lsJson.map((v) => {
      const item = {
        market: v.market,
        brand: v.brand,
        date: v.date,
        zone: v.zone,
        location: v.location,
        location_name: v.locationName,
      }
      Array.from({ length: 24 }).forEach((_, i) => {
        const hour = ++i
        const hasKey = `${hour}` in v
        item[hour] = hasKey ? v[hour] : 0
      })
      return item
    }) as LsCsvRowItem[],
    hasMissingMappings,
  }
}

export function isErcotMarket(
  data: LsCsvRowItem[] | LsErcotCsvRowItem[],
): boolean {
  const uniqueMarketValues = Array.from(new Set(data.map((obj) => obj.market)))

  // Check if there is only one unique value and it is 'ercot'
  return uniqueMarketValues.length === 1 && uniqueMarketValues[0] === 'ERCOT'
}

export async function convertLsCsvToJson(files: File[]) {
  const result = await Promise.all(
    files.map(async (file) =>
      csvToJson<LsCsvRowItem | LsErcotCsvRowItem>(file, 100_000),
    ),
  )

  const flattened = result.flatMap((v) => v.data)
  if (flattened.length <= 0) {
    return []
  }

  const filtered = flattened.map((v) => {
    const mmddyyyy = /^\d{2}\/\d{2}\/\d{4}$/
    if (mmddyyyy.test(v.date)) {
      v.date = DateTime.fromFormat(v.date, 'MM/dd/yyyy').toISODate()
    }
    return v
  })

  let validated

  const isErcot = isErcotMarket(
    filtered as LsCsvRowItem[] | LsErcotCsvRowItem[],
  )

  if (!isErcot) {
    validated = validateArrayWithZod<LsCsvRowItem>(
      filtered as LsCsvRowItem[],
      LsCsvRowValidation,
    )

    return validated.map((v: LsCsvRowItem) => {
      const item = {
        market: v.market,
        brand: v.brand,
        zone: v.zone,
        location: v.location,
        location_name: v.location_name,
        date: v.date,
      }
      Array.from({ length: 24 }).forEach((_, i) => {
        const hour = ++i
        const hasKey = `${hour}` in v
        item[hour] = applyIsoLoadRules(v.market, hasKey ? v[hour] : 0)
      })
      return item
    }) as LsCsvRowItem[]
  } else {
    validated = validateArrayWithZod<LsErcotCsvRowItem>(
      filtered as LsErcotCsvRowItem[],
      LsErcotCsvRowValidation,
    )

    return validated as LsErcotCsvRowItem[]
  }
}

// Latest NOP for Ercot changes

export function groupByMarket(
  data: STFPodItem[],
): Record<string, STFPodItem[]> {
  return data.reduce((result, item) => {
    const { market } = item
    if (!result[market]) {
      result[market] = []
    }
    result[market].push(item)
    return result
  }, {} as Record<string, STFPodItem[]>)
}

export function getLatestData(
  groupedData: Record<string, STFPodItem[]>,
): STFPodItem[] {
  const latestData: STFPodItem[] = []

  for (const market in groupedData) {
    const marketData = groupedData[market]

    const latestDataForMarket = marketData.reduce((result, current) => {
      const existingIndex = result.findIndex(
        (item) =>
          item.start_time === current.start_time &&
          item.market === current.market &&
          item.brand === current.brand &&
          item.zone === current.zone &&
          item.utility === current.utility,
      )

      if (existingIndex !== -1) {
        if (result[existingIndex].__time < current.__time) {
          result[existingIndex] = current
        }
      } else {
        result.push(current)
      }

      return result
    }, [] as STFPodItem[])

    latestData.push(...latestDataForMarket)
  }

  return latestData
}

interface AggregatedSTFData {
  start_time: string
  market: string
  brand: string
  zone: string
  forecast_net: number
  forecast_gross: number
}

function aggregateData(data: STFPodItem[]): AggregatedSTFData[] {
  const aggregatedData: Record<string, AggregatedSTFData> = {}

  data.forEach((item) => {
    const key = `${item.start_time}_${item.market}_${item.brand}_${item.zone}`

    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        start_time: item.start_time,
        market: item.market,
        brand: item.brand,
        zone: item.zone,
        forecast_net: item.forecast_net,
        forecast_gross: item.forecast_gross,
      }
    } else {
      aggregatedData[key].forecast_net += item.forecast_net
      aggregatedData[key].forecast_gross += item.forecast_gross
    }
  })

  return Object.values(aggregatedData)
}

export function groupByMarketNop(
  data: NOPPodItem[],
): Record<string, NOPPodItem[]> {
  return data.reduce((result, item) => {
    const { market } = item
    if (!result[market]) {
      result[market] = []
    }
    result[market].push(item)
    return result
  }, {} as Record<string, NOPPodItem[]>)
}

export function getLatestDataNop(
  groupedData: Record<string, NOPPodItem[]>,
): NOPPodItem[] {
  const latestData: NOPPodItem[] = []

  for (const market in groupedData) {
    const marketData = groupedData[market]

    const latestDataForMarket = marketData.reduce((result, current) => {
      const existingIndex = result.findIndex(
        (item) =>
          item.start_time === current.start_time &&
          item.market === current.market &&
          item.brand === current.brand &&
          item.zone === current.zone,
      )

      if (existingIndex !== -1) {
        // Replace existing data if the current data has a later __time value
        if (result[existingIndex].__time < current.__time) {
          result[existingIndex] = current
        }
      } else {
        result.push(current)
      }

      return result
    }, [] as NOPPodItem[])

    latestData.push(...latestDataForMarket)
  }

  return latestData
}

export interface AggregatedNopData {
  start_time: string
  market: string
  brand: string
  zone: string
  supply: number
  demand: number
  net_open_position: number
}

function aggregateDataNop(data: NOPPodItem[]): AggregatedNopData[] {
  const aggregatedData: Record<string, AggregatedNopData> = {}

  data.forEach((item) => {
    const key = `${item.start_time}_${item.market}_${item.brand}_${item.zone}`

    if (!aggregatedData[key]) {
      aggregatedData[key] = {
        start_time: item.start_time,
        market: item.market,
        brand: item.brand,
        zone: item.zone,
        supply: item.supply,
        demand: item.demand,
        net_open_position: item.net_open_position,
      }
    } else {
      aggregatedData[key].supply += item.supply
      aggregatedData[key].demand += item.demand
      aggregatedData[key].net_open_position += item.net_open_position
    }
  })

  return Object.values(aggregatedData)
}

interface UpdateResult {
  updatedData: LsErcotCsvRowItem[] | LsCsvRowItem[]
  stfCount: number
  nopCount: number
  changesMadeCount: number
  hasMissingMappings: boolean
}

async function nopWithLocationAndUpdatedDemand(
  stf: AggregatedSTFData[],
  nop: AggregatedNopData[],
): Promise<UpdateResult> {
  const stfCount = stf.length
  const nopCount = nop.length

  let changesMadeCount = 0
  const updatedNOP = [...nop].map((nopItem) => {
    const { start_time: startTime, market, brand, zone } = nopItem

    if (zone.includes('HB_')) {
      return nopItem
    }

    const matched = stf.find(
      (stfItem) =>
        stfItem.start_time === startTime &&
        stfItem.market === market &&
        stfItem.brand === brand &&
        (stfItem.zone === zone ||
          `LZ_${stfItem.zone}` === zone ||
          stfItem.zone === `LZ_${zone}`),
    )

    if (matched && nopItem.demand !== matched.forecast_gross) {
      changesMadeCount++
      nopItem.demand = matched.forecast_gross
    }

    return nopItem
  })

  const locationAttributes = await getLocationAttributes([], true)

  const activeLocationAttributes = locationAttributes.filter(
    (v) => v.isCredentialActive,
  )

  const attributeMap = new Map(
    activeLocationAttributes.map((attribute) => [
      `${attribute.brand}-${attribute.market}-${attribute.zone}`,
      attribute,
    ]),
  )

  const locationAttributeKeys = Array.from(attributeMap.keys())
  const podKeys = Object.keys(
    groupBy(updatedNOP, (v) => `${v.brand}-${v.market}-${v.zone}`),
  )

  const missingMappings = podKeys.filter(
    (item) => !locationAttributeKeys.includes(item),
  )

  const hasMissingMappings = !!missingMappings?.length

  if (hasMissingMappings) {
    console.info('MISSING_MAPPINGS::', missingMappings)
  }

  const pricing = await getPricingInfo()
  const roundingInfo = await getRoundingInfo()
  const targetNops = await getTargetNetOpenPositions()
  const defaultPricing = await getDefaultPriceInfo()

  const withLocations = updatedNOP
    .map((item) => {
      const { brand, market, zone, demand } = item
      const key = `${brand}-${market}-${zone}`
      const mappedAttribute = attributeMap.get(key)

      if (mappedAttribute) {
        const dateTime = DateTime.fromISO(item.start_time.toString(), {
          zone: TIMEZONES[market],
        })

        const interval = dateTime.hour + 1

        let supply = divide(item.supply, 1000)
        const load = applyIsoLoadRules(market, divide(demand, 1000))

        if (zone.includes('HB_') && supply < 0) {
          supply = 0
        }

        const targetNop = targetNops.find(
          (element) =>
            element.market === market &&
            element.brand === brand &&
            element.location === mappedAttribute.location,
        )

        const openPosition = Number((supply - load).toFixed(1))
        const bidOrOffer = openPosition < 0 ? 'EnergyBid' : 'EnergyOnlyOffer'

        const nopValue =
          targetNop !== undefined
            ? targetNop[openPosition < 0 ? 'bid' : 'offer']
            : 0

        const finalDemand = applyIsoLoadRulesErcot(
          toNumber(Math.abs(openPosition - nopValue)),
          roundingInfo,
          bidOrOffer,
        )

        const defaultPrice = defaultPricing.find(
          (element) => element.market === market,
        )

        const price = (pricing[
          `${market}_|_${mappedAttribute.location}_|_${brand}`
        ][interval] || defaultPrice)[
          bidOrOffer === 'EnergyBid' ? 'bidPrice' : 'offerPrice'
        ]

        return {
          ...item,
          supply,
          brand,
          interval,
          load,
          demand: finalDemand,
          date: dateTime.toISODate(),
          location: mappedAttribute.location,
          location_name: mappedAttribute.locationName,
          curve: 'variable',
          price: toNumber(price),
          net_open_position: nopValue,
          open_position: openPosition,
          bid_or_offer: bidOrOffer,
        }
      }
      return undefined
    })
    .filter((item) => item)

  return {
    updatedData: withLocations as LsErcotCsvRowItem[],
    stfCount,
    nopCount,
    changesMadeCount,
    hasMissingMappings,
  }
}

export async function mergeStfWithNop(stf: STFPodItem[], nop: NOPPodItem[]) {
  const stfGroupedByMarket = groupByMarket(stf)
  const stfGroupedByMarketWithLatestProcessTime =
    getLatestData(stfGroupedByMarket)
  const finalStf = aggregateData(stfGroupedByMarketWithLatestProcessTime)

  const nopGroupedByMarket = groupByMarketNop(nop)
  const nopGroupedByMarketWithLatestProcessTime =
    getLatestDataNop(nopGroupedByMarket)
  const finalNop = aggregateDataNop(nopGroupedByMarketWithLatestProcessTime)

  return nopWithLocationAndUpdatedDemand(finalStf, finalNop)
}

export function getErcotDayAheadBidsConvertSchema(): Column[] {
  return [
    {
      title: 'brand',
      accessor: 'brand',
      align: 'left',
      pinnable: true,
      sticky: 'left',
    },
    {
      title: 'market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'Zone',
      accessor: 'zone',
      align: 'left',
    },
    {
      title: 'location',
      accessor: 'location',
      align: 'left',
    },
    {
      title: 'location_name',
      accessor: 'location_name',
      align: 'left',
    },
    {
      title: 'date',
      accessor: 'date',
      align: 'right',
    },
    {
      title: 'interval',
      accessor: 'interval',
      align: 'right',
    },
    {
      title: 'load',
      accessor: 'load',
      align: 'right',
    },
    {
      title: 'supply',
      accessor: 'supply',
      align: 'right',
    },
    {
      title: 'open position',
      accessor: 'open_position',
      align: 'right',
    },
    {
      title: 'net open position',
      accessor: 'net_open_position',
      align: 'right',
    },
    {
      title: 'demand',
      accessor: 'demand',
      align: 'right',
    },
    {
      title: 'price',
      accessor: 'price',
      align: 'right',
    },
    {
      title: 'bid_or_offer',
      accessor: 'bid_or_offer',
      align: 'right',
    },
    {
      title: 'curve',
      accessor: 'curve',
      align: 'right',
    },
  ]
}

export interface ProductTypeAddition extends STFPodItem {
  product_type?: string
}

export async function getLsData(
  datasource: string,
  timezone: string,
  markets: string[],
  selectedNop?: Pod,
): Promise<UpdateResult> {
  const stfData = await getPodData(datasource, timezone)

  const formattedSTF = stfData.events.map((v: ProductTypeAddition) => {
    const { __time: time, ...rest } = v
    if (DateTime.isDateTime(time)) {
      return {
        ...rest,
        __time: time.toISO({
          includeOffset: false,
        }),
      }
    }

    return v
  })

  if (markets[0] === 'ercot') {
    const nopData = await getPodData(selectedNop?.datasource || '', timezone)

    const formattedNOP: NOPPodItem[] = nopData.events.map((v: NOPPodItem) => {
      const { __time: time, ...rest } = v
      if (DateTime.isDateTime(time)) {
        return {
          ...rest,
          __time: time.toISO({
            includeOffset: false,
          }),
        }
      }
      return v
    })

    return mergeStfWithNop(formattedSTF as STFPodItem[], formattedNOP)
  }

  const convertedLsJson = await convertPodJsonToLsJson(formattedSTF)

  return {
    updatedData: convertedLsJson.items,
    stfCount: 0,
    nopCount: 0,
    changesMadeCount: 0,
    hasMissingMappings: convertedLsJson.hasMissingMappings,
  }
}

export type ErcotLSSubmissionData = {
  brand: string
  date: string
  zone: string
  location: string
  intervals: {
    interval: string
    load: number
    price: number
    submitType: 'EnergyBid' | 'EnergyOnlyOffer'
    curveType: string
  }[]
}

export function formatToConvertPayloadErcot(
  data: LsErcotCsvRowItem[],
): ErcotLSSubmissionData[] {
  const groupedData = data.reduce((acc, obj) => {
    const key = `${obj.date}_${obj.location}_${obj.brand}`

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(obj)
    return acc
  }, {})

  const payload = Object.keys(groupedData).map((key) => {
    const groupedObjects = groupedData[key]
    return {
      brand: groupedObjects[0].brand,
      date: groupedObjects[0].date,
      zone: groupedObjects[0].zone,
      location: groupedObjects[0].location,
      intervals: groupedObjects.map((obj) => ({
        interval: obj.interval.toString(),
        load: obj.demand,
        price: obj.price,
        submitType: obj.bid_or_offer,
        curveType: obj.curve,
      })),
    }
  })
  return payload
}

export async function convertIbtCsvToJson(files: File[]) {
  const result = await Promise.all(
    files.map(async (file) => csvToJson<IbtCsvRowItem>(file, 100_000)),
  )

  const flattened = result.flatMap((v) => v.data)
  if (flattened.length <= 0) {
    return []
  }

  const filtered = flattened.map((v) => {
    const mmddyyyy = /^\d{2}\/\d{2}\/\d{4}$/
    if (mmddyyyy.test(v.date)) {
      v.date = DateTime.fromFormat(v.date, 'MM/dd/yyyy').toISODate()
    }
    return v
  })

  const validated = validateArrayWithZod<IbtCsvRowItem>(
    filtered,
    IbtCsvRowValidation,
  )

  return validated.map((v) => {
    const item = {
      contract_id: v.contract_id,
      contract_name: v.contract_name,
      market: v.market,
      brand: v.brand,
      zone: v.zone,
      buyer: v.buyer,
      buyer_qse_code: v.buyer_qse_code,
      seller: v.seller,
      seller_qse_code: v.seller_qse_code,
      date: v.date,
    }
    Array.from({ length: 24 }).forEach((_, i) => {
      const hour = ++i
      const hasKey = `${hour}` in v
      item[hour] = applyIsoLoadRules(v.market, hasKey ? v[hour] : 0)
    })
    return item
  }) as IbtCsvRowItem[]
}

export async function convertExpandedTradesPodData(
  items: ExpandedTradesPodData[],
): Promise<{
  items: IbtCsvRowItem[]
  hasMissingMappings: boolean
}> {
  if (items.length <= 0) {
    return {
      items: [],
      hasMissingMappings: false,
    }
  }

  const validated = validateArrayWithZod<ExpandedTradesPodData>(
    items,
    ExpandedTradesPodCsvRowValidation,
  )

  const orgIBTContracts = await getInternalBilateralContracts()

  const contractsMap = new Map(
    orgIBTContracts.map((contract) => [
      `${contract.market}-${contract.brand}-${contract.zone}-${contract.buyOrSell}-${contract.counterparty}`,
      contract,
    ]),
  )

  const locationAttributeKeys = Array.from(contractsMap.keys())
  const podKeys = Object.keys(
    groupBy(validated, (v) => `${v.brand}-${v.market}-${v.zone}`),
  )

  const missingMappings = podKeys.filter(
    (item) => !locationAttributeKeys.includes(item),
  )

  const hasMissingMappings = !!missingMappings?.length

  if (hasMissingMappings) {
    console.info('MISSING_MAPPINGS::', missingMappings)
  }

  const withContracts = validated
    .map((trade) => {
      const { market, counterparty, brand, zone, buy_sell: buySell } = trade
      const key = `${market}-${brand}-${zone}-${buySell}-${counterparty}`

      const mappedContract = contractsMap.get(key)
      if (mappedContract) {
        return {
          ...trade,
          contract_id: mappedContract.contractId,
          contract_name: mappedContract.contractName,
          ibt_contract_id: mappedContract.ibtContractId,
          seller_name: mappedContract.sellerName,
          seller_qse_code: mappedContract.sellerQseCode,
          buyer_name: mappedContract.buyerName,
          buyer_qse_code: mappedContract.buyerQseCode,
        }
      }

      return undefined
    })
    .filter((item) => item)

  const groupedByMarket = groupBy(withContracts, 'market')

  const aggragatedData = Object.values(groupedByMarket).reduce(
    (memo, value) => {
      const groupedByTime = groupBy(value, '__time')
      const [latestTime] = Object.keys(groupedByTime).sort((a, b) =>
        b.localeCompare(a),
      )
      const latestLoadForecast = groupedByTime[latestTime]

      const group = groupBy(
        latestLoadForecast,
        (v) =>
          `${v.market}-${v.brand}-${v.counterparty}-${v.zone}-${v.buy_sell}-${v.start_time}`,
      )

      const aggregation: AggregatedExpandedTrades[] = []
      for (const key in group) {
        const values = group[key]
        if (!values || values.length === 0) {
          continue
        }

        const {
          market,
          zone,
          start_time: startTime,
          da_rt: daRt,
          brand,
          counterparty,
          buy_sell: buySell,
          contract_id: contractId,
          contract_name: contractName,
          buyer_name: buyerName,
          buyer_qse_code: buyerQseCode,
          seller_name: sellerName,
          seller_qse_code: sellerQseCode,
        } = values[0]

        const dateTime = DateTime.fromISO(startTime.toString(), {
          zone: TIMEZONES[market],
        })

        const khz = values.reduce((previousValue, currentValue) => {
          const forecastGross = Number(currentValue.volume)
          return previousValue + (isNaN(forecastGross) ? 0 : forecastGross)
        }, 0)

        const totalPrice = values.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.price
        }, 0)

        const load = divide(khz, 1000)
        const mhz = applyIsoLoadRules(market, toNumber(load))

        aggregation.push({
          contractId,
          contractName,
          market,
          brand,
          zone,
          totalPrice,
          buySell,
          buyerName,
          buyerQseCode,
          sellerName,
          sellerQseCode,
          counterparty,
          startTime,
          khz,
          mhz,
          direction: daRt,
          isoDate: dateTime.toISO(),
          date: dateTime.toISODate(),
          interval: dateTime.hour + 1,
        })
      }

      memo.push(...aggregation)
      return memo
    },
    [] as AggregatedExpandedTrades[],
  )

  const groupByDate = groupBy(
    aggragatedData,
    (v) => `${v.market}-${v.brand}-${v.contractName}-${v.date}h`,
  )

  const json = Object.values(groupByDate).reduce(
    (previousValue, currentValue) => {
      const [
        {
          contractId,
          contractName,
          market,
          brand,
          date,
          zone,
          buyerName,
          buyerQseCode,
          sellerName,
          sellerQseCode,
        },
      ] = currentValue

      const bid = {
        contractId,
        contractName,
        market,
        brand,
        date,
        zone,
        buyer: buyerName,
        buyerQseCode,
        seller: sellerName,
        sellerQseCode,
      }

      currentValue.forEach((v) => {
        bid[v.interval] = v.mhz
      })

      previousValue.push(bid)
      return previousValue
    },
    [] as any[],
  )

  return {
    items: json.map((v) => {
      const item = {
        contract_id: v.contractId,
        contract_name: v.contractName,
        market: v.market,
        brand: v.brand,
        zone: v.zone,
        buyer: v.buyer,
        buyer_qse_code: v.buyerQseCode,
        seller: v.seller,
        seller_qse_code: v.sellerQseCode,
        date: v.date,
      }
      Array.from({ length: 24 }).forEach((_, i) => {
        const hour = ++i
        const hasKey = `${hour}` in v
        item[hour] = hasKey ? Math.abs(v[hour]) : 0
      })
      return item
    }) as IbtCsvRowItem[],
    hasMissingMappings,
  }
}

export function convertLsTimeToISODateFormat(time: string) {
  let currentTime = time

  if (DateTime.isDateTime(currentTime)) {
    currentTime = currentTime.toISO({
      includeOffset: false,
    })
  }
  return currentTime
}
