import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { login, logout, redirect } from 'shared/apiClient'
import { AvailablePodTypesMap, UserTools, UserType } from 'shared/types'

export const getLoginRedirectionUrl = async (state) => {
  try {
    const response = await redirect(state)
    if (response.url) {
      window.location.replace(response.url)
      return null
    }
    return response.user
  } catch (e) {
    return getLoginRedirectionUrl('framework-front')
  }
}

export const attemptLogin = async (code: string | null) => {
  return login(code).catch(() => null)
}

export const logoutUser = () => {
  logout().then((url) => {
    window.location.replace(url)
  })
}

export interface ReturnValue {
  userState: UserType | null
  login: () => void
  logout: () => void
}

/**
 * Full authentication flow behavior with
 * redirection logic after the user data is retrieved.
 * @param changePathname
 */
export default function useAuthenticationFlow(
  changePathname: (string) => void,
): ReturnValue {
  const [userState, setUserState] = useState<UserType | null>(null)
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')

  function initialize(user, path?) {
    // We need to process the insights/metrics from the podTypeSubscriptions in the claims
    const availablePodTypesMap: AvailablePodTypesMap =
      user.podTypeSubscriptions?.reduce((acc, val) => {
        const [insight, metric] = val.split(':') // format is insight:metric
        if (!acc[insight]) {
          acc[insight] = []
        }
        acc[insight].push(metric)
        return acc
      }, {}) || {}

    // We need to process the tools from the toolPolicies in the claims
    const availableTools: UserTools[] =
      user.toolPolicies?.map((toolPolicy) => {
        const [toolId] = toolPolicy.split(':') // format is insight:metric
        return toolId
      }, []) || []

    const newUser = {
      ...user,
      availableToolPolicies: user.toolPolicies,
      availableTools,
      availablePodTypesMap,
      availableDashboardType: user.dashboardType,
    }

    setUserState(newUser)
    if (path) {
      changePathname(path)
    }
  }

  useEffect(
    () => {
      if (!code) {
        return
      }
      if (searchParams.get('state') === 'framework-front') {
        attemptLogin(code).then((user) => {
          initialize(user)
        })
      }
    },
    /* This effect will only be executed on the initial mount phase,
    so there is no need to have exhaustive deps */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    userState,
    login: () => {
      if (
        (searchParams.get('code') &&
          searchParams.get('state') !== 'framework-front') ||
        (!searchParams.get('code') && !userState)
      ) {
        getLoginRedirectionUrl('framework-front').then((user) => {
          if (user) {
            initialize(user)
          }
        })
      }
    },
    logout: () => {
      // Remove everything but theme
      const theme = localStorage.getItem('theme')
      localStorage.clear()

      if (theme) {
        localStorage.setItem('theme', theme)
      }

      logoutUser()
    },
  }
}
